import * as FullStory from '@fullstory/browser';
import ReactDOM from 'react-dom';
import configuration from './lib/config';
import './styles/bootstrap.scss';

const pinMfePath = "/pinverify";
const isPinMfePath = window.location.pathname.startsWith(pinMfePath);

function importApp() {
  if (isPinMfePath) {
    return import('./PinMfe.js');
  } else {
    return import('./App.js');
  }
}

let baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');


Promise.all([
  importApp(),
  configuration()
]).then(([{ default: App }, config]) => {
  if (config.fullStoryOrgId) {
    FullStory.init({ orgId: config.fullStoryOrgId });
  }

  if (isPinMfePath) {
    baseUrl = pinMfePath;
  }

  ReactDOM.render(<App baseUrl={baseUrl} />, rootElement);
});